$fonts: (
        ('Roboto', 200, normal, 'Roboto-Light'),
        ('Roboto', 300, normal, 'Roboto-Regular'),
        ('Roboto', 400, normal, 'Roboto-Medium'),
        ('Roboto', 700, normal, 'Roboto-Bold')
);

@each $font-family, $font-weight, $font-style, $filename in $fonts {
    @font-face {
        font-family: $font-family;
        src: url('fonts/#{$filename}-webfont.eot');
        src: url('fonts/#{$filename}-webfont.eot?#iefix') format('embedded-opentype'),
        url('fonts/#{$filename}-webfont.woff') format('woff'),
        url('fonts/#{$filename}-webfont.ttf') format('truetype'),
        url('fonts/#{$filename}-webfont.svg#robotobold') format('svg');
        font-weight: $font-weight;
        font-style: $font-style;
    }
}
