body {
    font-weight: $font-weight;
}

a,
a:hover {
    text-decoration: none;
}

.important {
    color: red;
}

li {
    margin: 5px 0;
}
