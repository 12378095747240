.navbar-brand {
    line-height: inherit !important;
    padding-top: 16px !important;}

.list-challenges {
    padding-bottom: 100px;
    li {
        font-size: 21px;
        font-weight: 100;
    }
}

.panel {
    .row {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}

.pagination-nav {
    text-align: center;
}

.help {
    margin-bottom: 50px;
    .help-title {
        margin: 15px 0 5px 0;

        font-weight: bold;

        &:first-child {
            margin-top: 0px;
        }
    }

    .help-code {
        margin: 5px 0;

        font-family: 'Courier New', sans-serif;;
    }

    .help-content {
        margin: 5px 0;

        &:last-child {
            margin-bottom: 0;
        }

        a {
            font-weight: bold;
        }
    }
}


