@charset "UTF-8";
/************************************
 *   IMPORTS
 ************************************/
@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Light-webfont.eot");
  src: url("fonts/Roboto-Light-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Light-webfont.woff") format("woff"), url("fonts/Roboto-Light-webfont.ttf") format("truetype"), url("fonts/Roboto-Light-webfont.svg#robotobold") format("svg");
  font-weight: 200;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Regular-webfont.eot");
  src: url("fonts/Roboto-Regular-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Regular-webfont.woff") format("woff"), url("fonts/Roboto-Regular-webfont.ttf") format("truetype"), url("fonts/Roboto-Regular-webfont.svg#robotobold") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Medium-webfont.eot");
  src: url("fonts/Roboto-Medium-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Medium-webfont.woff") format("woff"), url("fonts/Roboto-Medium-webfont.ttf") format("truetype"), url("fonts/Roboto-Medium-webfont.svg#robotobold") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: "Roboto";
  src: url("fonts/Roboto-Bold-webfont.eot");
  src: url("fonts/Roboto-Bold-webfont.eot?#iefix") format("embedded-opentype"), url("fonts/Roboto-Bold-webfont.woff") format("woff"), url("fonts/Roboto-Bold-webfont.ttf") format("truetype"), url("fonts/Roboto-Bold-webfont.svg#robotobold") format("svg");
  font-weight: 700;
  font-style: normal; }

body {
  font-weight: 300; }

a,
a:hover {
  text-decoration: none; }

.important {
  color: red; }

li {
  margin: 5px 0; }

.navbar-brand {
  line-height: inherit !important;
  padding-top: 16px !important; }

.list-challenges {
  padding-bottom: 100px; }
  .list-challenges li {
    font-size: 21px;
    font-weight: 100; }

.panel .row {
  margin-top: 5px;
  margin-bottom: 5px; }

.pagination-nav {
  text-align: center; }

.help {
  margin-bottom: 50px; }
  .help .help-title {
    margin: 15px 0 5px 0;
    font-weight: bold; }
    .help .help-title:first-child {
      margin-top: 0px; }
  .help .help-code {
    margin: 5px 0;
    font-family: 'Courier New', sans-serif; }
  .help .help-content {
    margin: 5px 0; }
    .help .help-content:last-child {
      margin-bottom: 0; }
    .help .help-content a {
      font-weight: bold; }

.person .number {
  display: inline; }
  .person .number:before {
    content: '#'; }

.person .property-key {
  display: inline;
  font-weight: 600; }
  .person .property-key:after {
    content: ':';
    font-weight: 600; }

.person .property-value {
  display: inline; }

.person .ticket_fare:before {
  content: '£'; }

.jumbotron {
  margin-top: 100px; }
